@import "~@ui/styles/tools";

.container {
    display: flex;
    gap: 16px;
}

.section {
    border: 1px solid #20836D;
    box-shadow: 0px 10px 28px 0px rgba(136, 136, 136, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

    .container {
        align-items: center;
    }

    .input-component {
        margin-top: -10px;
    }

    .button--with-input {
        margin-top: 0;
    }
}

.call-to-action {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.1;

    margin-bottom: 0;
    width: 310px;
}

.delimiter {
    flex: 1;
    min-width: 75px;
}

.input {
    width: 400px;
    // height: 110px;
    
    @media screen and (max-width: 1650px) {
        width: 275px;
    }
}

.buttonContainer {
    margin: 0;
    padding: 0;
    border: none;
}

.input-component {
    height: 65px;
    background: #FFF;

    /* Disable browser autocomplete styles */
    :global(input:-webkit-autofill),
    :global(input:-webkit-autofill:hover), 
    :global(input:-webkit-autofill:focus) {
        border: inherit;
        -webkit-text-fill-color: inherit;
        box-shadow: 0 0 0px 1000px #FFFFFFFF inset;
        transition: inherit;
    }
}

.button--with-input {
    height: 65px;
    margin-top: -1px;
}

.button--with-input__text {
    font-size: 20px;
    
    @media screen and (max-width: 1825px) {
        font-size: 18px;
    }

    @include media-tablet {
        font-size: 12px;
    }
}

@include media-tablet {
    .call-to-action {
        font-size: 18px;
    }
}

@include media-mobile {
    .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 35px;
    }

    .call-to-action {
        font-size: 16px;
        width: 100%;
    }

    .input {
        width: 100%;
    }

    .delimiter {
        display: none;
    }

    .button {
        width: 100%;
    }

    .button--with-input__text {
        font-size: 16px;
    }
}