@import "~@ui/styles/tools";
@import "../../../../styles/variables.scss";

@mixin breakpoint-1450 {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

@mixin min-breakpoint-1450 {
    @media screen and (min-width: 1451px) {
        @content;
    }
}

.post__navigation {
    background: $color-off-white;
    border-radius: 24px;
    padding: 32px 16px 32px 32px;
    height: fit-content;
    max-width: 360px;
    position: sticky;
    top: 100px;
    max-height: 70vh;
    @include breakpoint-1450 {
        display: none;
        max-width: none;
    }
}

.post__navigation_scroll {
    overflow: auto;
    max-height: calc(70vh - 64px);
    padding-right: 16px;

    &::-webkit-scrollbar {
        width: 2px;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background: $color-grey-light;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-primary;
    }
}

.post__navigation_mobile {
    @include min-breakpoint-1450 {
        display: none;
    }
}

.post__navigation_header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
    letter-spacing: .2px;
    color: $color-title-active;
}

.post__navigation_h2 {
    display: block;
    margin: 32px 0 20px 0;
    color: $color-title-active;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: .2px;
    @include media-mobile {
        font-size: 18px;
    }
}

.post__navigation_h2:first-child {
    margin-top: 0;
}

.post__navigation_h3 {
    display: block;
    margin: 0 0 8px 16px;
    color: rgba(30, 32, 33, 0.5);
    font-weight: 500;
    font-size: 18px;
    letter-spacing: .2px;
    @include media-mobile {
        font-size: 16px;
    }
}

.post__navigation_h2:visited, .post__navigation_h3:visited {
    color: initial;
}